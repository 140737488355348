<template>
  <div class="tableContent">
    <el-table
      ref="table"
      :data="tableDataCompute"
      style="width: 100%"
      class="poctable"
      :height="tableContentHeight"
      stripe
    >
      <el-table-column prop="excelName" label="客户上传报表科目" width="180">
      </el-table-column>
      <el-table-column
        :label="`${this.$store.state.product_info.easy_name}标准报表科目`"
        width="180"
      >
        <template slot-scope="scope">
          <div class="row" style="justify-content: space-between">
            <span style="max-width: 96px">{{ scope.row.name }}</span>
            <div class="row" style="align-items: center;">
              <i
                style="margin-left: 10px; cursor: pointer"
                class="el-icon-edit"
                @click="add(scope.$index)"
              ></i
              ><i
                style="margin-left: 10px; cursor: pointer"
                class="el-icon-delete"
                @click="addListChangeData(scope.row)"
              ></i>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in tableHeader"
        :key="item.value"
        align="center"
      >
        <template slot="header" slot-scope="">
          <div @click="handleOpenDialog(item, index)">
            <el-link style="font-size: 12px" :underline="false" type="primary">{{
              item.value | yearFilter
            }}</el-link
            ><i style="margin-left: 5px; cursor: pointer" class="el-icon-edit"></i>
          </div>
        </template>
        <template slot-scope="{ row }">
          <el-input
            size="small"
            v-model="row[item.key].value"
            placeholder="请输入内容"
            @change="change"
            :disabled="row.disabled ? true : false"
          ></el-input>
          <!-- <span>{{ item }}</span> -->
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="修改时间" :visible.sync="dialogFormVisible" width="30%" center>
      <el-form :model="form" size="small">
        <el-form-item label="年份" :label-width="formLabelWidth">
          <el-select v-model="form.year" placeholder="请选择年份">
            <el-option
              v-for="(item, index) in selectList"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="月份" :label-width="formLabelWidth">
          <el-select v-model="form.month" placeholder="请选择月份">
            <el-option
              v-for="month in monthList"
              :key="month"
              :label="month"
              :value="month"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleChangeYear" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="选择科目" :visible.sync="chooseName" width="30%" center>
      <el-input placeholder="请输入" v-model="selectChooseName"></el-input>
      <div
        style="max-height: 400px; margin-top: 20px; overflow: hidden; overflow-y: auto"
      >
        <div style="margin-bottom: 10px" v-for="(name, i) in selectChooseNameFn" :key="i">
          <el-link @click="replaceName(i, name)" :underline="false">{{ name }}</el-link>
        </div>

        <div v-if="!selectChooseNameFn.length" class="el-select-dropdown__empty">
          暂无数据
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as sesstion from "../store/localStorage";
import { uploadr_save } from "@/api";
import json from "./demo/data";
export default {
  props: {
    tableData: Array,
    listData: Array,
    yearData: Array,
    tableLable: String,
    index: Number,
    companyCode: String,
    change: Function,
    tableContentHeight: Number,
  },
  filters: {
    yearFilter(date) {
      let dateStr = date + "";
      if (dateStr.length == 4) return dateStr;
      else {
        return dateStr.substr(0, 4) + "-" + dateStr.substr(4);
      }
    },
  },
  computed: {
    selectChooseNameFn() {
      const selectArr = [];
      const selectChooseName = this.selectChooseName;
      const arr = this.listChangeData;
      if (selectChooseName === "") return arr;
      arr.some((name) => {
        if (name.indexOf(selectChooseName) + 1) selectArr.push(name);
      });
      return selectArr;
    },
    tableDataCompute() {
      var arr = [];
      this.tableData.map((item) => {
        if (!item.hidden) arr.push(item);
      });
      return arr;
    },
  },
  data() {
    return {
      chooseIndex: 0,
      chooseName: false,
      selectChooseName: "",
      value: "",
      tableHeader: [],
      chooseHead: {
        2016: 2016,
        2017: 2017,
        2018: 2018,
        2019: 2019,
        2020: 2020,
      },

      startStatus: "请选择",
      currentTableData: [],
      currentYearData: "",
      obj: {},
      // isSave: "",
      totalData: {},
      dialogFormVisible: false, //弹出框显示隐藏
      form: {
        key: "",
        year: "",
        month: 12,
        index: "",
      },
      monthList: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
      formLabelWidth: "73px",
      selectList: [2016, 2017, 2018, 2019, 2020],
      FZZCTableData: {},
      LRTableData: {},
      XJLLTableData: {},
      listChangeData: [],
      selectChooseName: [],
      timer: null,
    };
  },
  created() {
    this.listChangeData = [...this.listData];
  },
  mounted() {
    // console.log(this.yearData.yearArr)

    // 没有修改的话存原始值
    this.Storage(this.tableData);
    this.getTableData();
  },
  watch: {
    chooseName() {
      if (this.chooseName === false) this.selectChooseName = "";
    },
    tableData: {
      handler(val) {
        // console.log(val);
        this.Storage(val);
      },
      // 这里是关键，代表递归监听 demo 的变化
      deep: true,
    },
  },
  beforeUpdate() {},
  methods: {
    close() {},
    add($index) {
      this.chooseName = true;
      this.chooseIndex = $index;
    },
    replaceName(i, name) {
      const rowName = this.tableData[this.chooseIndex].name;

      this.tableData[this.chooseIndex].name = name;
      this.chooseName = false;
      this.listChangeData.splice(this.listChangeData.indexOf(name), 1);

      if (rowName) this.listChangeData.unshift(rowName);
      this.change();
    },
    addListChangeData(row) {
      row.name && this.listChangeData.unshift(row.name);

      row.name = "";
      this.change();
    },
    dataFilter(val) {
      if (val) {
        this.listChangeData = [];
        let length = val.length;
        let arr = [];
        this.listData.forEach((item) => {
          if (item.substring(0, length) === val) {
            arr.push(item);
          }
        });
        this.listChangeData = [...arr];
      } else {
        this.listChangeData = [...this.listData];
      }
    },
    // 存储值
    Storage(val) {
      if (this.tableLable == "资产负债表") {
        this.FZZCTableData[this.tableLable] = {
          listData: this.listData,
          tableData: val,
        };
        sesstion.setLocalData("FZZCTableData", this.FZZCTableData);
      }
      if (this.tableLable == "利润表") {
        this.LRTableData[this.tableLable] = {
          listData: this.listData,
          tableData: val,
        };
        sesstion.setLocalData("LRTableData", this.LRTableData);
      }
      if (this.tableLable == "现金流量表") {
        this.XJLLTableData[this.tableLable] = {
          listData: this.listData,
          tableData: val,
        };
        sesstion.setLocalData("XJLLTableData", this.XJLLTableData);
      }
    },

    handleOpenDialog(currentItem, currentIndex) {
      let dateStr = currentItem.value + "";
      var year = dateStr.substr(0, 4);
      var month = dateStr.substr(4);

      this.$set(this.form, "year", year);
      this.$set(this.form, "month", month);
      this.$set(this.form, "key", currentItem.key);
      this.$set(this.form, "index", currentIndex);

      // this.$set(this.tableHeader, "key", currentItem.key)
      // this.form.year = currentItem.year;
      // this.form.key = currentItem.key;

      this.dialogFormVisible = true;
    },
    handleChangeYear() {
      this.$nextTick(() => {
        // 修改表头显示年份
        this.tableHeader[this.form.index].value = this.form.year + this.form.month;

        this.$set(
          this.tableHeader[this.form.index],
          "value",
          this.form.year + this.form.month
        );

        // 修改table中每一项的年份值
        this.tableData.forEach((item) => {
          item[this.form.key].year = this.form.year + this.form.month;
        });

        this.dialogFormVisible = false;
      });
    },

    getTableData() {
      // console.log(this.yearData, this.index);
      // 获取表格总数据
      // this.currentTableData = sesstion.getLocalData("fileTableData");

      this.tableHeader = [...this.yearData[this.index].yearList];
      // this.selectList = [...this.yearData[this.index].yearArr[0]];
      // console.log(this.yearData, this.selectList);
    },

    renderHeaderA(h, index, prop) {
      let filters = [
        {
          text: "2016年",
          value: 2016,
        },
        {
          text: "2017年",
          value: 2017,
        },
        {
          text: "2018年",
          value: 2018,
        },
        {
          text: "2019年",
          value: 2019,
        },
        {
          text: "2020年",
          value: 2020,
        },
      ];
      return h("div", [
        h(
          "el-select",
          {
            class: "level-sel",
            on: {
              input: (value) => {
                // that.$set(that.chooseHead[prop] = value)
                this.$set(this.chooseHead, prop, value);
                this.tableHeader.forEach((item, index) => {
                  // console.log(prop, item);
                  if (!!parseInt(prop) && prop === item) {
                    // console.log(prop, item);
                    this.$set(this.tableHeader, index, value + "");
                    this.tableData.forEach((item) => {
                      item[value] = item[prop];
                    });
                    // this.tableHeader[index] = value
                  }
                });

                // this.$set(this.tableHeader, this.tableHeader.length - 1, value);
                // console.log(this.chooseHead, value, prop, this.tableHeader);
              },
            },
            props: {
              //文字框的内容取决于这个value，如果value不存在，会报错
              value: this.chooseHead[prop] || this.startStatus,
            },
          },
          [
            filters.map((item) => {
              return h("el-option", {
                props: {
                  value: item.value,
                  label: item.text,
                },
              });
            }),
          ]
        ),
      ]);
    },
  },
  // handleEdit(index, row) {
  //   console.log(index, row);
  // },

  beforeUpdate() {
    this.$nextTick(() => {
      //在数据加载完，重新渲染表格
      this.$refs["table"].doLayout();
    });
  },
};
</script>

<style lang="less" scoped>
.poctable th {
  background-color: #f2f9ff !important;
  color: #1989fa;
  .el-input__inner {
    border: none;
    background-color: #f2f9ff;
    color: #1989fa;
  }
  .el-select .el-input .el-select__caret {
    color: #1989fa;
  }
}
.color_pick input::-webkit-input-placeholder {
  color: #1989fa;
  
}
</style>
