<template>
  <div>
    <div class="info">
      <div class="row" style="width: 100%">
        <div>
          <span class="lable">企业名称：</span>
          <span style="font-weight:550">{{companyName}}</span>
          <!-- <el-input v-model="companyName" :disabled="true" size="small"></el-input> -->
        </div>
        <div class="uploader">
          <span class="lable">上传报表：</span>
          <span style="font-weight:550">{{currentTableData.name}}</span>
          <!-- <el-input
            v-model="currentTableData.name"
            :disabled="true"
            ref="upload"
            placeholder=""
          ></el-input> -->
          <!-- <div :class="{ disUp: disUploader }">重新上传</div> -->
        </div>
        <div>
          <span style="width: 59px" class="lable">识别率：</span>
          <span style="color: #ff624f; font-size: 17px"
            >{{ currentTableData.rate }}%</span
          >
        </div>
      </div>
    </div>
    <!--  以上是企业信息   -->
    <div class="table_info">
      <el-tabs type="card" v-model="activeName">
        <el-tab-pane
          v-for="(item, index) in tableTextList"
          :key="item.name"
          :label="item.name"
          :name="item.name"
        >
          <PocTable
            ref="tttt"
            :index="index"
            :tableData="item.tableData"
            :listData="item.listData"
            :yearData="yearData"
            :tableLable="item.name"
            :companyCode="companyCode"
            @getCurrentTableData="getCurrentTableData"
            :change="change"
            :tableContentHeight="tableContentHeight"
          />
        </el-tab-pane>
        <!-- <el-button type="primary" @click="submit" size="small">提交</el-button> -->
        <!-- <el-tab-pane label="利润表" name="second">
          <PocTable
            :tableData="tableData"
            :yearData="yearData"
            :listData="listData"
            :tableLable="tableLable"
          />
        </el-tab-pane>
        <el-tab-pane label="现金流量表" name="third">
          <PocTable :tableData="tableData" :listData="listData" :yearData="yearData" :tableLable="tableLable"/>
        </el-tab-pane> -->
      </el-tabs>
      <!-- <i-button class="sub" type="primary" @click="submit"
        >提交全部数据</i-button
      > -->
    </div>
  </div>
</template>

<script>
import * as sesstion from "../store/localStorage";
import PocTable from "@/components/PocTable";
import { compute } from "@/api/dataManager";
export default {
  props: {
    companyCode: String,
    // companyName: String,
  },
  data() {
    return {
      rate: "",
      form: {
        company_name: "",
        company_industry: "",
        // reportType: "00",
        // reportTime: "00",
        uploadFileName: "",
        // subjectNature: "一般企业",
      },
      disUploader: true,
      tableLable: "资产负债表",
      tableTextList: [],
      creatData: "asdasdasdasdas",
      activeName: "资产负债表",
      currentTableData: [],
      tableData: [],
      listData: [],
      yearData: [],
      submitTableData: {},
      isSave: "",
      companyName: sessionStorage.companyName,
      tableContentHeight: window.innerHeight - 181 - 60 - 50 - 51 - 59,
    };
  },

  components: {
    PocTable,
  },
  mounted() {
    // this.creatData = sesstion.getLocalData("creatData");
    // console.log(this.creatData);
  },
  created() {
    this.getTableData();
    window.onresize = this.setHeight.bind(this);
  },
  methods: {
    setHeight() {
      this.tableContentHeight = window.innerHeight - 181 - 60 - 50 - 51 - 59;
    },
    saveData() {
      //没有加载 FillCompanyDataTable 组件 则有问题
      // console.log(this.$refs.upload.saveData())
      // console.log(999)
      // console.log(this.$refs.poctable.saveData())
      // this.$refs.poctable.saveData().then((res) => {
      //   console.log(res);
      // }).catch(err => {
      //   console.error(err)
      // })
      // console.log(this.$refs.tttt)
    },
    getCurrentTableData(totalData) {
      // totalData  例 {tableLable: "资产负债表", tableData: ...}

      totalData.tableData = JSON.parse(totalData.tableData);

      // this.currentTableData.table_data[totalData.tableLable].tableData = totalData.tableData
      this.tableTextList[totalData.index].tableData = totalData.tableData;
      // 回显
      Object.keys(this.submitTableData.table_data).forEach((item) => {
        if (this.submitTableData.table_data[item].listData) {
          this.submitTableData.table_data[item] = {
            tableData: this.submitTableData.table_data[item].tableData,
          };
        }
      });

      this.submitTableData.table_data[totalData.tableLable] = {
        tableData: totalData.tableData,
      };
    },
    change() {
      let params = {
        company_code: sessionStorage.company_code,
        table_data: this.submitTableData.table_data,
      };

      compute(params).then(({ data }) => {
        var fileTableData = sesstion.getLocalData("fileTableData");

        fileTableData.table_data = data.table_data;
        fileTableData.listData = data.listData;
        sesstion.setLocalData("fileTableData", fileTableData);
        this.getTableData(1);
      });
    },
    submit() {
      let params = {
        company_code: this.companyCode,
        table_data: this.submitTableData.table_data,
      };

      var config = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          // "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.isLogin.loginStore.auth_token,
        },
      };
      this.$axios
        .post(`${window.BASE_URL}/upload/multiple-fs/save/`, params, config)
        .then(({ data }) => {
          // console.log(data);
          this.$message({
            type: "success",
            message: data.message,
          });
          if (data.message == "报表保存成功") {
            // console.log(this.$parent);
            this.$parent.onTab(0);
            this.$parent.myDataNextPage(1);
            // resolve(this.isSave)
          } else {
            // reject(this.isSave)
          }
        })
        .catch((err) => {
          console.error(err);
          this.isSave = "";
        });
    },
    getTableData(flag) {
      // 获取表格总数据
      this.currentTableData = sesstion.getLocalData("fileTableData");

      // alert(Object.keys(this.currentTableData.table_data)[0]);
      if (flag != 1) {
        var keys = Object.keys(this.currentTableData.table_data);
        this.activeName = keys.includes("资产负债表") ? "资产负债表" : keys[0];
      }

      this.submitTableData = { ...this.currentTableData };
      // this.tableData = this.currentTableData.table_data["资产负债表"].tableData;
      // this.listData = this.currentTableData.table_data["资产负债表"].listData;
      var index = 0;
      var year_index = 0;
      if (this.currentTableData.table_data["资产负债表"]) {
        this.tableTextList[index++] = {
          name: "资产负债表",
          tableData: this.currentTableData.table_data["资产负债表"].tableData || [],
          listData: this.currentTableData.table_data["资产负债表"].listData || [],
        };
        this.yearData[year_index++] = this.updateYearData(
          this.currentTableData.table_data["资产负债表"].tableData,
          this.currentTableData.table_data["资产负债表"].years,
          this.currentTableData.table_data["资产负债表"].columns
        );
      }
      if (this.currentTableData.table_data["利润表"]) {
        this.tableTextList[index++] = {
          name: "利润表",
          tableData: this.currentTableData.table_data["利润表"].tableData || [],
          listData: this.currentTableData.table_data["利润表"].listData || [],
        };
        this.yearData[year_index++] = this.updateYearData(
          this.currentTableData.table_data["利润表"].tableData,
          this.currentTableData.table_data["利润表"].years,
          this.currentTableData.table_data["利润表"].columns
        );
      }
      if (this.currentTableData.table_data["现金流量表"]) {
        this.tableTextList[index++] = {
          name: "现金流量表",
          tableData: this.currentTableData.table_data["现金流量表"].tableData || [],
          listData: this.currentTableData.table_data["现金流量表"].listData || [],
        };
        this.yearData[year_index++] = this.updateYearData(
          this.currentTableData.table_data["现金流量表"].tableData,
          this.currentTableData.table_data["现金流量表"].years,
          this.currentTableData.table_data["现金流量表"].columns
        );
      }

      this.yearData = this.yearData.filter((item) => item);
      this.tableTextList = this.tableTextList.filter((item) => item);

      // console.log(this.tableData, this.listData);
    },
    updateYearData(tabledata, years, columns) {
      // console.log(tabledata, years);
      //写成一个方法
      let yearData = {
        yearList: [],
        yearArr: [],
      };

      yearData.yearArr.push(years);
      // yearData.columns = columns;
      // let yearData = []
      columns.forEach((item, index) => {
        let obj = {
          key: item,
          value: years[index],
        };
        yearData.yearList.push(obj);
      });

      // tabledata.forEach((item, index) => {
      //   // 获取到year 长度 年份数组
      //   let keys = Object.keys(item);
      //   keys = keys.filter((item1) => {
      //     return item1 != "excelName" && item1 != "name";
      //   });

      //   // yearData.length = keys.length;
      //   // console.log(yearData.yearArr)
      //   // 年份数据
      //   let obj = {};
      //   keys.forEach((item2) => {
      //     // let obj = {
      //     //   year: "",
      //     //   value: "",
      //     // };
      //     // obj.year = item2;

      //     obj[item2] = item[item2];
      //   });
      //   // console.log(arr);
      // });
      // yearData.yearList.push(obj);
      return yearData;
    },

    // open() {
    //   this.$alert("这是一段内容", "标题名称", {
    //     confirmButtonText: "确定",
    //     callback: (action) => {
    //       this.$message({
    //         type: "info",
    //         message: `action: ${action}`,
    //       });
    //     },
    //   });
    // // },
    // handleClick(tab, event) {
    //   // console.log(tab, event);
    // },
  },
};
</script>

<style lang="less" scoped>
.info {
  font-size: 14px;
  display: flex;
  padding: 0 5px;
  .ivu-radio-group {
    margin-top: 9px;
  }
  > div {
    padding: 0 10px;

    // display: flex;
    > div {
      display: flex;
      height: 32px;
      align-items: center;
      margin-bottom: 10px;
      margin-right: 30px;
      .lable {
        width: 80px;
      }
    }
  }
}
.table_info {
  padding: 20px;
  padding-top: 0;
  box-sizing: border-box;
}

.disUp {
  color: #ccc !important;
}
.sub {
  position: absolute;
  padding: 4px 20px;
  border-radius: 20px;
  margin-left: 63%;
  margin-top: 30px;
}
.msg {
  color: #2057ac;
  font-weight: 700;
  padding: 0 3px;
}
.msg2 {
  color: red;
  font-weight: 700;
}
</style>
