<template>
  <div
    class="data-manager-wrap bgc_w pm-4"
    style="padding-bottom: 15px; position: relative"
  >
    <div style="margin-bottom: 20px; background: #fff">
      <div
        class="data-manager-header"
        style="padding: 15px; border-bottom: 1px solid #f0f0f0"
      >
         <div class="title">
        <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
        上传报表
      </div>
      </div>
    </div>
    <fill-company-step-three
      :companyCode="companyCode"
      :companyName="companyName"
      ref="3"
    ></fill-company-step-three>
    <div
      class="bts"
      style="
        background: #fff;
        text-align: center;
        width: 100%;
        left: 0;
      "
    >
      <el-button @click="$router.go(-1)">上一步</el-button>
      <el-button type="primary" @click="onSaveTable">提交数据</el-button>
    </div>
  </div>
</template>
<script>
import * as sesstion from "@/store/localStorage";
export default {
  components: {
    FillCompanyStepThree: require("@/components/FillCompanyStepThree").default,
  },
  created() {},
  methods: {
    onSaveTable() {
      const FZZCTableData = sesstion.getLocalData("FZZCTableData");
      const LRTableData = sesstion.getLocalData("LRTableData");
      const XJLLTableData = sesstion.getLocalData("XJLLTableData");

      this.obj = {};
      this.obj.company_code = this.companyCode || "";
      this.obj.table_data = {};
      if (FZZCTableData != null) {
        this.obj.table_data["资产负债表"] = FZZCTableData["资产负债表"];
      }
      if (LRTableData != null) {
        this.obj.table_data["利润表"] = LRTableData["利润表"];
      }
      if (XJLLTableData != null) {
        this.obj.table_data["现金流量表"] = XJLLTableData["现金流量表"];
      }

      this.checkData();
      // this.open();
    },
    openCheck(msg) {
      this.$confirm(msg, "提示", {
        confirmButtonText: "提交",
        cancelButtonText: "继续填写",
        dangerouslyUseHTMLString: true,
        customClass: "submitConfirm",
        // type: "warning",
      })
        .then(() => {
          this.submit();
        })
        .catch(() => {});
    },
    submit() {
      let params = this.obj;
      // console.log(params);
      var config = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          // "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.isLogin.loginStore.auth_token,
        },
      };
      this.$axios
        .post(`${window.BASE_URL}/upload/multiple-fs/save/`, params, config)
        .then(({ data }) => {
          sesstion.removeLocalData("FZZCTableData");
          sesstion.removeLocalData("LRTableData");
          sesstion.removeLocalData("XJLLTableData");
          if (data.message == "报表保存成功") {
            this.$message({
              type: "success",
              message: data.message,
            });
            this.$router.go(-2);
          } else {
            this.$message({
              type: "success",
              message: data.message,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          // this.isSave = "";
        });
    },
    // 提交数据
    checkData() {
      let params = this.obj;
      params.company_code = sessionStorage.company_code;
      var unit = sesstion.getLocalData("fileTableData").unit;
      // console.log(params);
      var config = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          // "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.isLogin.loginStore.auth_token,
        },
      };
      params.unit = unit;
      this.$axios
        .post(`${window.BASE_URL}/upload/multiple-fs/check/`, params, config)
        .then(({ data }) => {
          if (data.check_status == false) {
            let arr = [];
            data.message.forEach((v) => {
              arr.push(v);
            });
            var str = "";
            // console.log(arr);
            arr.forEach((v, i) => {
              if (i == arr.length - 1) {
                str = str + "<div style='padding-top:20px'>" + v + "</div>";
              } else {
                str = str + "<div>" + v + "</div>";
              }
            });

            this.openCheck(str);
          } else if (data.check_status == true) {
            this.submit();
          }
        })
        .catch((err) => {
          this.$Message.error(err);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.data-manager-wrap{
  border-radius: 8px;
  overflow: hidden;
}
.title{
  font-weight: 550;
}

</style>
